import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, RelatedContent, SecondaryCallout, PageSection, AnchorLinks, PSAImageStepsList, PageSubSection, PageIntro, FeaturedCTA, TextBlock } from '../../components';
import prepStep1 from '../../assets/jc04657-preparation-step-01.png';
import prepStep2 from '../../assets/jc04657-preparation-step-02.png';
import prepStep3 from '../../assets/jc04657-preparation-step-03.png';
import prepStep4 from '../../assets/jc04657-preparation-step-04.png';
import prepStep5 from '../../assets/jc04657-preparation-step-05.png';
import prepStep6 from '../../assets/jc04657-preparation-step-06.png';
import prepStep7 from '../../assets/jc04657-preparation-step-07.png';
import adminStep1 from '../../assets/jc04657-preparation-administration-1.png';
import adminStep2 from '../../assets/jc04657-preparation-administration-2.png';
import adminStep3 from '../../assets/jc04657-preparation-administration-3.png';
import storageStep1 from '../../assets/jc04657-preparation-storage-bag.png';
import storageStep2 from '../../assets/jc04657-preparation-storage-disposal.png';
import psaCount1 from '../../assets/psaCount-1.png';
import psaCount2 from '../../assets/psaCount-2.png';
import psaCount3 from '../../assets/psaCount-3.png';
import psaCount4 from '../../assets/psaCount-4.png';
import psaCount5 from '../../assets/psaCount-5.png';
import psaCount6 from '../../assets/psaCount-6.png';
import psaCount7 from '../../assets/psaCount-7.png';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Preparation, Storage and Administration of KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda storage, keytruda administration, keytruda preparation`,
    description: `Health care professionals may find information about preparation, storage and administration of KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/preparation-storage-administration/","@type":"MedicalWebPage","name":"Preparation","description":"Preparation of KEYTRUDA® (pembrolizumab) is the same regardless of fixed or weight-based dosing"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/preparation-storage-administration/","@type":"MedicalWebPage","name":"Storage of Diluted Solution","description":"At room temperature for no more than 6 hours from the time of dilution. This includes room temperature storage of the diluted solution, and the duration of infusion. Under refrigeration at 2°C to 8°C (36°F to 46°F) for no more than 96 hours from the time of dilution. If refrigerated, allow the diluted solution to come to room temperature prior to administration. Do not shake."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/preparation-storage-administration/","@type":"MedicalWebPage","name":"Administration","description":"Administer diluted solution via infusion pump intravenously over 30 minutes through an intravenous line containing a sterile, nonpyrogenic, low-protein binding 0.2–5 micron in-line or add-on filter."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/preparation-storage-administration/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[34].jobCode;

const relatedContent = [
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/options/',
            label: 'Dosing'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/dose-modifications/',
            label: 'Dose Modifications'
        }
    },
]

const anchorLinkModelData = [
    { label: 'Preparation' }, 
    { label: 'Storage' }, 
    { label: 'Administration' }
]

// Numbers in the <ol> (first item below) should be green
const PSAPrepSteps = [
    {
        image: prepStep1,
        count: psaCount1,
        alt: '',
        stepNumAlt: '',
        instruction: 'Aseptic technique should be strictly observed throughout the preparation process.'
    },
    {
        image: prepStep2,
        count: psaCount2,
        alt: '',
        stepNumAlt: '',
        instruction: 'Visually inspect the solution for particulate matter and discoloration. The solution is clear to slightly opalescent, colorless to slightly yellow. Discard the vial if visible particles are observed.'
    },
    {
        image: prepStep3,
        count: psaCount3,
        alt: '',
        stepNumAlt: '',
        instruction: 
            'Dilute KEYTRUDA prior to intravenous administration. ',
        details: `
            <ul>
                <li>If the intravenous bag is prefilled with normal saline, excess volume should be removed to ensure the final concentration of diluted solution is between 1 mg/mL and 10 mg/mL.</li>
                <li>If an empty intravenous bag is being used, withdraw the necessary volume of normal saline from another appropriate bag and inject into the empty bag prior to adding KEYTRUDA.</li>
            </ul>
        `,
    },
    {
        image: prepStep4,
        count: psaCount4,
        alt: '',
        stepNumAlt: '',
        instruction: 'Withdraw the required volume from the vial(s) of KEYTRUDA, using a sterile syringe attached to a suitable needle.',
        details: `
            <ul>
                <li>Transfer it into an intravenous bag containing 0.9% Sodium Chloride Injection, USP or 5% Dextrose Injection, USP. The vial may need to be inverted to remove the solution.</li>
                <li>Each vial of KEYTRUDA contains 100 mg/4 mL. For each individual vial, consider using a suitable size single-use syringe and a new needle for each vial.</li>
            </ul>
        `,
    },
    {
        image: prepStep5,
        count: psaCount5,
        alt: '',
        stepNumAlt: '',
        instruction: 'The final concentration of the diluted solution should be between 1 mg/mL and 10 mg/mL.'
    },
    {
        image: prepStep6,
        count: psaCount6,
        alt: '',
        stepNumAlt: '',
        instruction: 'Mix the diluted solution by gently inverting the bag.',
        details: '<h4>It is important that you do not shake the bag. If the infusion bag is excessively handled or shaken, particulates may form. If this occurs, discard the bag and create a new bag taking care not to shake.</h4>'
    },
    {
        image: prepStep7,
        count: psaCount7,
        alt: '',
        stepNumAlt: '',
        instruction: 'Discard any unused portion of the vial.',
        end: true
    },
]

const PSAStorageSteps = [
    {
        image: storageStep1,
        alt: '',
        instruction: 'Storage of Diluted Solution',
        details: `
            <p>The product does not contain a preservative.</p>
            <p><b>Store the diluted solution from the KEYTRUDA 100 mg/4 mL vial either:</b></p>
            <ul>
                <li>At room temperature for no more than 6 hours from the time of dilution. This includes room temperature storage of the diluted solution, and the duration of infusion.</li>
                <li>Under refrigeration at 2°C to 8°C (36°F to 46°F) for no more than 96 hours from the time of dilution. If refrigerated, allow the diluted solution to come to room temperature prior to administration. <b>Do not shake.</b></li>
            </ul>
        `,
    },
    {
        image: storageStep2,
        alt: '',
        instruction: 'Discard the diluted solution after 6 hours at room temperature or after 96 hours under refrigeration.',
        warning: 'Do not freeze.',
        end: true
    },
    
]

const PSAAdminSteps = [
    {
        image: adminStep1,
        count: psaCount1,
        alt: '',
        stepNumAlt: '',
        instruction: 'Before starting the infusion, attach the infusion line to the pump and prime the line either with 0.9% Sodium Chloride Injection, USP; 5% Dextrose Injection, USP; or infusion solution.'
    },
    {
        image: adminStep2,
        count: psaCount2,
        alt: '',
        stepNumAlt: '',
        instruction: 'Administer diluted solution via infusion pump intravenously over 30 minutes through an intravenous line containing a sterile, nonpyrogenic, low-protein binding 0.2 to 5 micron in-line or add-on filter.',
        details: `
            <ul>
                <li>Ensure the entire contents of the bag are dosed during the time window.</li>
            </ul>
        `,
        warning: 'Do not co-administer other drugs through the same infusion line.'       
    },
    {
        image: adminStep3,
        count: psaCount3,
        alt: '',
        stepNumAlt: '',
        instruction: 'Flush the infusion line with 0.9% Sodium Chloride Injection, USP or 5% Dextrose Injection, USP, to ensure the entire contents of the bag are dosed.',
        end: true
    },
]



const Page = ({ location }) => {
    return (
        <DefaultLayout  jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro" maxWidth={824}>
                    <PageHeader title="Preparation, Storage & Administration" />
                    <PageSection bgColor="white">
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection bgColor='cloud'>
                        <SecondaryCallout 
                            title="Preparation of KEYTRUDA® (pembrolizumab) is the same regardless of fixed or weight-based dosing" 
                            alt=""    
                        />
                        <PageIntro title="Preparation">
                            Supplies to consider but not limited to: KEYTRUDA® (pembrolizumab) vial(s); infusion bag and pump; 0.9% Sodium Chloride Injection [United States Pharmacopeia (USP)] or 5% Dextrose Injection, USP; sterile, nonpyrogenic, low-protein binding 0.2 to 5 micron in-line or add-on filter; infusion set; alcohol swabs; syringes; and needles.
                        </PageIntro>
                        <PageSubSection title="Step-by-Step Prep Instructions">
                            <PSAImageStepsList data={PSAPrepSteps} stepCount={PSAPrepSteps.length} theme='cloud'></PSAImageStepsList>
                        </PageSubSection>
                    </PageSection>

                    <PageSection title="Storage">
                        <PSAImageStepsList data={PSAStorageSteps} stepCount={PSAStorageSteps.length} theme='cloud'></PSAImageStepsList>
                    </PageSection>

                    <PageSection bgColor='cloud' title="Administration">
                        <PSAImageStepsList data={PSAAdminSteps} stepCount={PSAAdminSteps.length} theme='cloud'></PSAImageStepsList>
                    </PageSection>
                    <PageSection title="Monitoring Considerations">
                        <TextBlock>
                            KEYTRUDA can cause severe or life-threatening infusion-related reactions, including hypersensitivity and anaphylaxis, which have been reported in 0.2% of 2799 patients receiving KEYTRUDA. Monitor patients for signs and symptoms of infusion-related reactions including rigors, chills, wheezing, pruritus, flushing, rash, hypotension, hypoxemia, and fever. Interrupt or slow the rate of infusion for mild (Grade 1) or moderate (Grade 2) infusion-related reactions. For severe (Grade 3) or life-threatening (Grade 4) infusion-related reactions, stop infusion and permanently discontinue KEYTRUDA.
                        </TextBlock>
                        <FeaturedCTA 
                            title="Please read additional information about Dose Modifications for Immune-Mediated Adverse Reactions"
                            ctas={[{label: "Dose Modifications", url: "/dosing/dose-modifications"}]}
                            iconAlt=""
                        />
                    </PageSection>

                    <PageSection bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
